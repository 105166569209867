<script>
import slideshowService from '@/services/slideshow.service'
import libraryService from '@/services/library.service'
import Slide from '@/data/models/Slide'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleSlideshow',
  components: {
    EditLayout
  },
  data() {
    return {
      items: [],
      item: new Slide,
      photo: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },  
  mounted() {
    this.isNew ? this._getAll() : this._get()
  },
  methods: {
    _getAll() {
      slideshowService.getAll()
        .then(res => {
          this.items = res.data
          this.item.position = res.data.length + 1
        })
    },
    _get() {
      slideshowService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
        })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    create() {
      slideshowService.create({ data: this.item })
        .then(response => {
          this.$notify({
            message: this.$tc('m.itemSuccessfullyAdded', { item: this.$tc('slide') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'slideshow' })
        })
    },
    edit() {
      slideshowService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$tc('m.itemSuccessfullySaved', { item: this.$tc('slide') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
  }
}
</script>

<template>
  <EditLayout 
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="sidebar-middle">
      <div>
        <BaseInput
          v-model="item.position"
          :label="$t('position')"
          disabled
        />
      </div>
      <div>
        <BaseInput
          v-model="item.link"
          :label="$tc('link')"
          :placeholder="$t('enterLink')"
        />
      </div>
    </div>
  </EditLayout>
</template>