/* eslint-disable camelcase */
export default class Slide {
  description;
  library_id;
  link;
  name;
  position;
  published;

  constructor() {
    this.description = '';
    this.library_id = '';
    this.link = '';
    this.name = '';
    this.position = 999;
    this.published = 0;
  }
}
